@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0 0 0;
    --background-rgb: 255 255 255;
    --color-primary: 167 52 187;
    --color-secondary: 54 207 109;
    --color-accent: 79 70 229;
}

.main-container {
    background-color: rgb(var(--background-rgb));
}

body {
    color: rgb(var(--foreground-rgb));
}

.split-type {
    font-kerning: none;
}

.footer-links a {
    transition: 0.2s;
}

.footer-links a:hover {
    animation: slight-jump 0.2s cubic-bezier(0.3, 0.7, 0.1, 0.2) forwards;
}

@keyframes slight-jump {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-3px);
    }

    100% {
        transform: translateY(0);
    }
}

/*region Scrollbar*/
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    @apply bg-gray-200;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(var(--color-primary) / 0.8);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(var(--color-primary) / 1);
}

/*endregion*/
